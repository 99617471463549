<template>
  <div>
    <v-card>
      <v-alert v-if="record.actualizado" dark color="warning"
        >Registro Actualizado</v-alert
      >
      <v-alert v-if="record.nuevo" dark color="success"
        >Registro Creado</v-alert
      >
      <v-card-title>
        Examens
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-select
            placeholder="Seleccione Carrera"
            outlined
            v-model="model.carrera.id"
            :items="carreras"
            item-text="nombre"
            item-value="id"
          />
          <v-autocomplete
            placeholder="Seleccione Materia"
            outlined
            v-model="model.materia_id"
            :items="uCurricularCarreras"
            item-text="searchTagSinAcento"
            item-value="id"
          >
          </v-autocomplete>
          <v-text-field
            outlined
            label="Ingrese Turno Examen"
            v-model="model.turno"
          ></v-text-field>
          <v-text-field
            clearable
            outlined
            label="Ingrese Fecha del Examen"
            type="date"
            v-model="model.date"
            :onemptied="null"
          ></v-text-field>
          <v-text-field
            outlined
            label="Ingrese Hora del Examen"
            type="time"
            v-model="model.hora"
            :onemptied="null"
          ></v-text-field>

          <v-text-field
            outlined
            label="Ingrese Fecha de Inicio de inscripción"
            type="date"
            v-model="model.fecha_inicio_inscripcion"
          ></v-text-field>
          <v-text-field
            outlined
            label="Ingrese Fecha del Fin de la inscripción"
            type="date"
            v-model="model.fecha_fin_inscripcion"
          ></v-text-field>

          <!--          <div v-for="(label,field) in filteredFields" :key="field">-->
          <!--            <slot :name="field" :model="model">-->
          <!--            <v-text-field outlined v-model="model[field]" :label="label" :error-messages="errors[field]"></v-text-field>-->
          <!--            </slot>-->
          <!--          </div>-->

          <v-switch
            :true-value="1"
            :false-value="0"
            v-model="model.estado"
            :label="model.estado ? 'Activo' : 'Inactivo'"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "ExamenForm",
  props: {
    // id: [String, Number],
  },
  data() {
    return {
      searchMateria: "",
      id: null,
      only: [],
      exclude: ["docenteExamen", "docentes", "cursado_id", "materia_id"],
      model: { carrera: {} },
    };
  },
  computed: {
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("UCurricularCarreraModule", ["materiasPorCarrera"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapGetters("ExamenModule", ["errors"]),
    ...mapState("ExamenModule", ["fields", "examen", "record"]),

    materiasPorCarreraFiltered() {
      return this.materiasPorCarrera.filter(
        (el) => el.nombre == this.searchMateria
      );
    },

    filteredFields() {
      let items = [];
      items = Object.keys(this.fields)
        .filter((key) => !this.only.length > 0 || this.only.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.fields[key];
          return obj;
        }, {});
      items = Object.keys(items)
        .filter((key) => !this.exclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = items[key];
          return obj;
        }, {});
      return items;
    },
  },
  methods: {
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    // ...mapActions("UCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("ExamenModule", [
      "getFields",
      "getExamen",
      "updateExamen",
      "addExamen",
    ]),

    getMaterias() {
      this.getUCurricularCarreras({
        filters: { carrera_id: this.model.carrera.id },
      });
    },

    async getModel() {
      await this.getExamen({ id: this.id, expand: ["carrera"].toString() });
      this.model = this.examen;
    },
    async guardar() {
      if (!this.model.fecha) {
        this.model.fecha = null;
      }
      // this.model.fecha = moment(this.model.fecha).format('Y-M-D');
      // this.model.fecha_inicio_inscripcion = moment(this.fecha_inicio_inscripcion).format();
      // this.model.fecha_fin_inscripcion = moment(this.fecha_fin_inscripcion).format();
      this.model.id
        ? await this.updateExamen(this.model)
        : await this.addExamen(this.model);
      // this.$emit('close');
    },
    cerrar() {
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.$emit("close");
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getModel();
      }
    },
    "model.date": function () {
      if (this.model.date) {
        this.model.fecha = this.model.date + " " + this.model.hora;
      } else {
        this.model.fecha = null;
      }
    },
    "model.hora": function () {
      if (this.model.hora) {
        this.model.fecha = this.model.date + " " + this.model.hora;
      } else {
        this.model.fecha = null;
      }
    },
    "model.carrera.id": function () {
      this.getMaterias();
    },
  },
  created() {
    this.getCarreras();
    this.getFields();
    // if (this.id) {
    //   this.getModel();
    // }
  },
};
</script>

<style scoped></style>
