<template>
  <v-dialog persistent eager v-model="dialog" max-width="600" scrollable>
    <v-card>
      <v-alert v-if="record.actualizado" dark color="warning"
        >Registro Actualizado</v-alert
      >
      <v-alert v-if="record.nuevo" dark color="success"
        >Registro Creado</v-alert
      >
      <v-card-title>
        Cursados
        <v-btn link icon absolute top right @click="cerrar"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-autocomplete
            placeholder="Seleccione Carrera"
            outlined
            v-model="model.carrera.id"
            :items="carreras"
            item-text="nombre"
            item-value="id"
            @change="getMaterias"
          />
          <v-autocomplete
            placeholder="Seleccione Materia"
            outlined
            v-model="model.espacio_id"
            :items="uCurricularCarreras"
            item-text="searchTagSinAcento"
            item-value="id"
          ></v-autocomplete>
          <div v-for="(label, field) in filteredFields" :key="field">
            <slot :name="field" :model="model">
              <v-text-field
                outlined
                v-model="model[field]"
                :label="label"
                :error-messages="errors[field]"
              ></v-text-field>
            </slot>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div>
          <v-btn @click="guardar" color="primary">Guardar</v-btn>
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn @click="cerrar">Cancelar</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "CursadoForm",
  props: {
    id: [String, Number],
  },
  data() {
    return {
      dialog: false,
      only: [
        "anio_lectivo",
        "cupo",
        "cuatrimestre",
        "comision",
        "aula_campus",
        "inicio_inscripcion",
        "fin_inscripcion",
        "fecha_inicio",
        "fecha_fin",
        "fecha_vencimiento",
      ],
      exclude: [],
      model: { carrera: {} },
    };
  },
  computed: {
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("CarreraModule", ["carreras"]),
    ...mapGetters("NewCursadoModule", ["errors"]),
    ...mapState("NewCursadoModule", ["fields", "cursado", "record"]),
    filteredFields() {
      let items = [];
      items = Object.keys(this.fields)
        .filter((key) => !this.only.length > 0 || this.only.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.fields[key];
          return obj;
        }, {});
      items = Object.keys(items)
        .filter((key) => !this.exclude.includes(key))
        .reduce((obj, key) => {
          obj[key] = items[key];
          return obj;
        }, {});
      return items;
    },
  },
  methods: {
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("NewCursadoModule", [
      "getFields",
      "getCursado",
      "updateCursado",
      "addCursado",
    ]),

    getMaterias() {
      this.getUCurricularCarreras({
        filters: { carrera_id: this.model.carrera.id },
      });
    },

    async getModel() {
      await this.getCursado(this.id);
      this.model = this.cursado;
    },
    async guardar() {
      this.model.id
        ? await this.updateCursado(this.model)
        : await this.addCursado(this.model);
      // this.$emit('close');
    },
    cerrar() {
      this.record.actualizado = false;
      this.record.nuevo = false;
      this.$emit("close");
    },
  },
  watch: {
    id() {
      if (this.id) {
        this.getModel();
      }
    },
  },
  created() {
    this.getCarreras();
    this.getFields();
    if (this.id) {
      this.getModel();
    }
  },
};
</script>

<style scoped></style>
