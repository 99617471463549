<template>
  <div>
    <calendario-horario ref="calendario"></calendario-horario>

    <docente-cursado-a-b-m
      @close="reload"
      ref="docentes"
      :cursado="cursado"
      :cursado_id="id"
    ></docente-cursado-a-b-m>

    <horario-cursado-a-b-m
      @close="reload"
      ref="horarioForm"
      :cursado="cursado"
      :cursado_id="id"
    ></horario-cursado-a-b-m>

    <ciclo-lectivo-form @close="reload" ref="cicloForm"></ciclo-lectivo-form>

    <cursado-form ref="form" :id="id" @close="cerrar">
      <template v-slot:cuatrimestre="{ model }">
        <v-select
          outlined
          label="Ingrese Cuatrimestre"
          :items="cuatrimestres"
          v-model="model.cuatrimestre"
        ></v-select>
      </template>
      <template v-slot:inicio_inscripcion="{ model }">
        <v-text-field
          type="date"
          v-model="model.inicio_inscripcion"
          label="Ingrese Inicio Inscripción"
          outlined
        ></v-text-field>
      </template>
      <template v-slot:fin_inscripcion="{ model }">
        <v-text-field
          type="date"
          v-model="model.fin_inscripcion"
          label="Ingrese Fin Inscripción"
          outlined
        ></v-text-field>
      </template>
      <template v-slot:fecha_inicio="{ model }">
        <v-text-field
          type="date"
          v-model="model.fecha_inicio"
          label="Ingrese Inicio Cursada"
          outlined
        ></v-text-field>
      </template>
      <template v-slot:fecha_fin="{ model }">
        <v-text-field
          type="date"
          v-model="model.fecha_fin"
          label="Ingrese Final de cursada"
          outlined
        ></v-text-field>
      </template>
      <template v-slot:fecha_vencimiento="{ model }">
        <v-text-field
          type="date"
          v-model="model.fecha_vencimiento"
          label="Ingrese Fecha Vencimiento"
          outlined
        ></v-text-field>
      </template>
    </cursado-form>

    <examen-form-cursado
      ref="examenForm"
      :cursado_id="cursado.id"
    ></examen-form-cursado>
    <v-card>
      <v-card-title>Cursados</v-card-title>
      <v-card-text>
        <div class="d-flex justify-content-between w-100">
          <div class="">
            <v-btn @click="newItem" color="primary">Nuevo</v-btn>
          </div>
          <div class="">
            <v-switch
              class="mt-0 pt-0"
              v-model="options.vencidos"
              label="Vencidos"
            ></v-switch>
          </div>
          <div class="">
            <v-btn @click="$refs.cicloForm.dialog = true" color="secondary"
              >Crear Ciclo Lectivo</v-btn
            >
          </div>
          <div class="">
            <v-btn outlined @click="verCalendario" color="secondary"
              ><v-icon>mdi-calendar</v-icon>Calendario</v-btn
            >
          </div>

          <!--          <v-spacer></v-spacer>-->
          <div class="d-flex">
            <v-select
              dense
              outlined
              hide-details
              :items="acciones"
              v-model="accion"
            ></v-select>
            <v-btn color="primary" @click="ejecutarAccion">Ejecutar</v-btn>
          </div>
          <div class="d-flex justify-content-end">
            <div class="d-flex">
              <download-excel :data="excel">
                <v-btn dark color="success">Exportar</v-btn>
              </download-excel>
              <download-excel
                :escapeCsv="false"
                type="csv"
                :data="alumnosCampus"
              >
                <v-btn dark color="warning">Campus</v-btn>
              </download-excel>
            </div>
          </div>
        </div>
        <v-row>
          <v-col>
            <v-text-field
              label="Buscar por campo"
              v-model="search.tag"
              clearable
              @click:clear="reload"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          v-model="selectedItems"
          show-select
          :loading="loading.status"
          :items="cursados"
          :headers="headers"
          :server-items-length="pagination.totalCount"
          :options.sync="options"
        >
          <template v-slot:body.prepend>
            <filter-row ref="filterRow" :headers="headers" @reload="reload">
              <!--              <template v-slot:fecha_inicio="{}">-->
              <!--                <v-text-field-->
              <!--                  hide-details-->
              <!--                  outlined-->
              <!--                  dense-->
              <!--                  multiple-->
              <!--                  type="date"-->
              <!--                  v-model="filters['fecha_inicio']"-->
              <!--                ></v-text-field>-->
              <!--              </template>-->
              <!--              <template v-slot:fecha_fin="{}">-->
              <!--                <v-text-field-->
              <!--                  hide-details-->
              <!--                  outlined-->
              <!--                  dense-->
              <!--                  multiple-->
              <!--                  type="date"-->
              <!--                  v-model="filters['fecha_fin']"-->
              <!--                ></v-text-field>-->
              <!--              </template>-->
              <!--              <template v-slot:fecha_vencimiento="{}">-->
              <!--                <v-text-field-->
              <!--                  hide-details-->
              <!--                  outlined-->
              <!--                  dense-->
              <!--                  multiple-->
              <!--                  type="date"-->
              <!--                  v-model="filters['fecha_vencimiento']"-->
              <!--                ></v-text-field>-->
              <!--              </template>-->
              <template v-slot:campo_id="">
                <v-autocomplete
                  clearable
                  hide-details
                  outlined
                  dense
                  multiple
                  :items="campos"
                  v-model="filters['campo_id']"
                  item-value="id"
                  item-text="nombre"
                ></v-autocomplete>
              </template>

              <template v-slot:carrera_id="">
                <v-autocomplete
                  clearable
                  hide-details
                  outlined
                  dense
                  multiple
                  :items="carreras"
                  v-model="filters['carrera_id']"
                  item-value="id"
                  item-text="codigo"
                ></v-autocomplete>
              </template>
              <template v-slot:u_curricular_carrera.nombre="{}">
                <v-autocomplete
                  clearable
                  hide-details
                  outlined
                  dense
                  multiple
                  :items="uCurricularCarreras"
                  v-model="filters['cursado.espacio_id']"
                  item-value="id"
                  item-text="searchTagSinAcento"
                ></v-autocomplete>
              </template>
              <template v-slot:campo.codigo="{}">
                <v-autocomplete
                  clearable
                  hide-details
                  outlined
                  dense
                  multiple
                  :items="campos"
                  v-model="filters['campo_id']"
                  item-value="id"
                  item-text="nombre"
                ></v-autocomplete>
              </template>
            </filter-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon color="info" class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon color="info" @click="deleteItem(item.id)">
              mdi-delete
            </v-icon>
            <v-btn
              v-if="item.aula_campus"
              target="_blank"
              link
              icon
              :href="item.aula_campus"
              ><v-icon color="info"> mdi-link </v-icon></v-btn
            >
          </template>

          <template v-slot:item.u_curricular_carrera.nombre="{ item }">
            {{ item.espacio.nombre }}
          </template>

          <template v-slot:item.docente_id="{ item }">
            <v-card
              dark
              color="primary"
              class="p-2"
              @click="abrirDocentes(item)"
            >
              <div v-for="docente in item.docentes" :key="docente.id">
                {{ docente.apellido }}
              </div>
            </v-card>
          </template>

          <template v-slot:item.horarios="{ item }">
            <v-card color="" class="p-2" @click="abrirHorarios(item)">
              <div class="" v-for="horario in item.horarios" :key="horario.id">
                <div class="text-nowrap">
                  {{ horario.dia.substring(0, 3) }}
                  {{ horario.hora_inicio.substring(0, 5) }} a
                  {{ horario.hora_fin.substring(0, 5) }}
                </div>
              </div>
            </v-card>
          </template>
          <template v-slot:item.estado="{ item }">
            <v-chip v-if="item.estado" color="success">Activo</v-chip>
            <v-chip v-else color="error">Desactivado</v-chip>
          </template>
          <template v-slot:item.cursado.cuatrimestre="{ item }">
            {{ item.cuatrimestre }}
          </template>

          <template v-slot:item.campo_id="{ item }">
            {{ item.espacio.campo.codigo }}
          </template>
          <template v-slot:item.u_curricular_carrera.anio="{ item }">
            {{ item.espacio.anio }}
          </template>
          <template v-slot:item.cantInscriptos="{ item }">
            <v-btn target="_blank" :href="'/alumno-cursado/' + item.id">{{
              item.alumnoCursados ? item.alumnoCursados.length : 0
            }}</v-btn>
          </template>
          <template v-slot:item.carrera_id="{ item }">
            {{ item.carrera.codigo }}
          </template>
          <template v-slot:item.campus_export="{ item }">
            <v-switch
              @change="(event) => updateCampusStatus(event, item)"
              class="mt-0 pt-0"
              v-model="item.campus_export"
              label="Campus"
            ></v-switch>
          </template>
          <template v-slot:item.planilla="{ item }">
            <div>
              <v-btn
                target="_blank"
                :to="'/planilla-digital-cursado/' + item.id"
                link
                @click="verPlanilla(item)"
                >Planilla</v-btn
              >
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import CursadoForm from "@/viewsNew/cursado/CursadoForm";
import CicloLectivoForm from "@/viewsNew/cursado/CicloLectivoForm";
import FilterRow from "@/viewsNew/utils/FilterRow";
import HorarioCursadoABM from "@/viewsNew/horario-cursado/HorarioCursadoABM";
import DocenteCursadoABM from "@/viewsNew/docente-cursado/DocenteCursadoABM";
import CalendarioHorario from "@/viewsNew/cursado/CalendarioHorario";
import ExamenFormCursado from "@/viewsNew/examen/ExamenFormCursado";

export default {
  name: "CursadoABM",
  components: {
    ExamenFormCursado,
    CalendarioHorario,
    DocenteCursadoABM,
    HorarioCursadoABM,
    FilterRow,
    CicloLectivoForm,
    CursadoForm,
  },
  data() {
    return {
      campus: [],
      export_id: null,
      acciones: [
        { value: 1, text: "Activar" },
        { value: 2, text: "Desactivar" },
        { value: 3, text: "Eliminar Seleccionados" },
        { value: 4, text: "Nueva Comisión" },
        { value: 5, text: "Calendario" },
        { value: 6, text: "Dividir Comisión" },
        // { value: 7, text: "Exportar Alumnos a Campus" },
      ],
      accion: null,
      cursado: {},
      filters: {},
      dialogHorarios: false,
      cuatrimestres: ["anual", "primero", "segundo"],
      selectedItems: [],
      like: {},
      dialog: false,
      id: null,
      options: {
        joins: ["carrera"],
      },
      expand: ["docentes", "carrera", "espacio", "horarios", "alumnoCursados"],
    };
  },
  computed: {
    ...mapState("CarreraModule", ["carreras"]),
    ...mapState("CampoModule", ["campos"]),
    ...mapState("NewUCurricularCarreraModule", ["uCurricularCarreras"]),
    ...mapState("NewCursadoModule", ["cursados", "pagination"]),
    ...mapState(["search", "loading", "dialogs"]),

    alumnosCampus() {
      let items = [];
      for (const cursado of this.selectedItems) {
        for (const alumnoCursado of cursado.alumnoCursados) {
          let item = { id: alumnoCursado.alumno.dni };
          items.push(item);
        }
      }
      return items;
    },

    excel() {
      let items = [];
      for (var i in this.cursados) {
        const cursado = this.cursados[i];
        let item = {
          carrera: cursado.carrera.codigo,
          campo: cursado.espacio.campo.codigo,
          anio: cursado.espacio.anio,
          espacio: cursado.espacio.nombre,
          anio_lectivo: cursado.anio_lectivo,
          comision: cursado.comision,
          cuatrimestre: cursado.cuatrimestre,
          cupo: cursado.cupo,
          inscriptos: cursado.cantInscriptos,
          docentes: cursado.docentes.map((el) => el.apellido).toString(),
          horarios: cursado.horariosFormateados.corto,
        };
        items.push(item);
      }

      return items;
    },

    filteredMaterias() {
      const filter = this.filters;
      return this.uCurricularCarreras.filter(function (item) {
        for (var key in filter) {
          if (item[key] != filter[key]) return false;
        }
        return true;
      });
    },

    filterHeaders() {
      return this.headers.filter((el) => el.filterable);
    },

    headers() {
      return [
        { text: "Id", value: "id", filterable: true },
        { text: "Carrera", value: "carrera_id", filterable: true },
        {
          text: "Campo",
          value: "campo_id",
          filterable: true,
          sortable: false,
        },
        { text: "Año", value: "u_curricular_carrera.anio", filterable: true },
        {
          text: "Categoria",
          value: "espacio.categoria",
          filterable: true,
          table: "u_curricular_carrera.categoria",
        },
        {
          text: "Materia",
          value: "u_curricular_carrera.nombre",
          filterable: true,
        },
        { text: "Estado", value: "estado", filterable: true },
        { text: "Año Lectivo", value: "anio_lectivo", filterable: true },
        { text: "Inicio", value: "fecha_inicio", filterable: true },
        { text: "Fin", value: "fecha_fin", filterable: true },
        { text: "Vencimiento", value: "fecha_vencimiento", filterable: true },
        { text: "Nro Comisión", value: "comision", filterable: true },
        {
          text: "Cuatrimestre",
          value: "cursado.cuatrimestre",
          filterable: true,
        },
        { text: "Cupo", value: "cupo", filterable: true },
        { text: "Campus", value: "campus_export", filterable: true },

        {
          text: "Inscriptos",
          value: "cantInscriptos",
          filterable: true,
          nosearch: true,
        },
        {
          text: "docentes",
          value: "docente_id",
          filterable: true,
          nosearch: true,
        },
        {
          text: "Horarios",
          value: "horarios",
          filterable: false,
          sortable: false,
          nosearch: true,
        },
        // { text: "Examen", value: "examen", filterable: false, nosearch: true },
        {
          text: "Planilla",
          value: "planilla",
          filterable: false,
          nosearch: true,
        },

        {
          text: "Acciones",
          value: "actions",
          filterable: false,
          nosearch: true,
        },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$refs.form.model = {};
        this.id = null;
      }
    },
    filters: {
      handler() {
        this.setFilters();
        this.reload();
      },
      deep: true,
    },
    like: {
      handler() {
        this.reload();
      },
      deep: true,
    },

    search: {
      handler() {
        this.setSearch();
        this.reload();
      },
      deep: true,
    },

    options: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("NewUserModule", ["getUsers"]),
    ...mapActions("NewAlumnoCursadoModule", ["getAlumnoCursados"]),
    ...mapActions("CarreraModule", ["getCarreras"]),
    ...mapActions("CampoModule", ["getCampos"]),
    ...mapActions("NewUCurricularCarreraModule", ["getUCurricularCarreras"]),
    ...mapActions("NewCursadoModule", [
      "dividirComision",
      "getCursados",
      "deleteCursado",
      "updateCursado",
      "addCursado",
    ]),
    ...mapMutations(["clearFilters", "setFilters", "setSearch"]),

    csvExport(arrData, name = "export.csv") {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        // Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", name);
      link.click();
    },

    async fetchData(item) {
      const userIds = item.alumnoCursados.map((el) => el.alumno_id);
      const users = await this.getUsers({
        options: { itemsPerPage: -1 },
        expand: ["userCampus"].toString(),
        filters: { id: userIds },
      });
      const userCampus = users.map((el) => el.userCampus);
      console.log(userCampus);
      let items = [];
      for (var i in userCampus) {
        let registro = {
          usuario: userCampus[i],
        };
        items.push(registro);
      }
      const name = item.espacio.searchTag + "-COMISION:" + item.comision;
      this.csvExport(items, name);
    },
    startDownload(event) {
      console.log("event", event);
      this.export_id = 1;
      console.log("show loading");
    },
    finishDownload() {
      console.log("hide loading");
    },

    ejecutarAccion() {
      switch (this.accion) {
        case 1:
          this.cambiarEstado(1);
          break;
        case 2:
          this.cambiarEstado(0);
          break;
        case 3:
          this.borrarSeleccionados();
          break;
        case 4:
          this.nuevaComision();
          break;
        case 5:
          this.verCalendario();
          break;
        case 6:
          this.handleDividirComision();
          break;
        // case 7:
        //   this.handleExportarAlumnos();
        //   break;
        default:
          return;
      }
      // this.selectedItems = [];
      // this.action = null;
    },

    verCalendario() {
      this.$refs.calendario.dialog = true;
      this.$refs.calendario.cursos = this.selectedItems;
      this.selectedItems = [];
    },

    async nuevaComision() {
      for (var i in this.selectedItems) {
        let model = { ...this.selectedItems[i] };
        model.comision = +model.comision + 1;
        this.addCursado(model);
      }
      this.selectedItems = [];
      this.accion = null;
    },

    async borrarSeleccionados() {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar todos los registros?",
        title: "Eliminar Registro",
      });
      if (res) {
        for (var i in this.selectedItems) {
          this.deleteCursado(this.selectedItems[i].id);
        }
        this.selectedItems = [];
        this.accion = null;
      }
    },

    async cambiarEstado(flag) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea cambiar el estado de los cursados?",
        title: "Cambiar Estado Cursado",
      });
      if (res) {
        for (var i in this.selectedItems) {
          let model = this.selectedItems[i];
          model.estado = flag;
          this.updateCursado(model);
        }
        this.selectedItems = [];
        this.accion = null;
      }
    },

    async abrirDocentes(item) {
      this.id = item.id;
      this.cursado = item;
      // await this.$refs.docentes.reload();
      this.$refs.docentes.dialogDocentes = true;
    },

    async abrirHorarios(item) {
      this.id = item.id;
      this.cursado = item;
      // await this.$refs.horarioForm.reload();
      this.$refs.horarioForm.dialogHorarios = true;
    },

    newItem() {
      this.$refs.form.model = { carrera: {} };
      this.$refs.form.dialog = true;
    },

    cerrar() {
      this.$refs.form.dialog = false;
      this.reload();
    },

    editItem(item) {
      this.id = item.id;
      this.$refs.form.dialog = true;
    },

    async updateCampusStatus(event, item) {
      console.log("event: ", event);
      console.log("item: ", item);
      item.campus_export = event ? 1 : 0;
      this.updateCursado(item);
    },

    async deleteItem(id) {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea eliminar el registro?",
        title: "Eliminar Registro",
      });
      if (res) {
        this.deleteCursado(id);
      }
    },

    async reload() {
      this.selectedItems = [];
      this.loading.status = true;

      await this.getCursados({
        like: this.$refs.filterRow.like,
        options: this.options,
        filters: this.filters,
        expand: this.expand.toString(),
        search: this.search.tag,
        headers: this.headers,
      });
      this.loading.status = false;
    },

    crearExamen(item) {
      // this.cursados = item;
      this.$refs.examenForm.cursado = item;
      this.$refs.examenForm.dialog = true;
    },

    handleExportarAlumnos() {},

    async handleDividirComision() {
      const res = await this.$dialog.confirm({
        color: "error",
        icon: "mdi-alert",
        text: "Esta seguro que desea dividir estas comisiones",
        title: "Eliminar Registro",
      });
      if (res) {
        for (var i in this.selectedItems) {
          this.dividirComision({ cursado_id: this.selectedItems[i].id });
        }
        this.selectedItems = [];
        this.accion = null;
        this.reload();
      }
    },
  },
  destroyed() {
    this.clearFilters();
  },
  created() {
    this.getCampos();
    this.getCarreras();
    this.getUCurricularCarreras({
      options: { itemsPerPage: -1 },
      // filters: this.filters,
      // like: this.$refs.filterRow.like,
    });
  },
};
</script>

<style scoped></style>
